import { default as pino, type Logger } from 'pino';

const level = process.env.LOG_LEVEL ?? 'info';

const logger = pino({
    // by default pino logs the level as a number, but we want the string label.
    // see - https://github.com/pinojs/pino/blob/HEAD/docs/help.md#log-levels-as-labels-instead-of-numbers
    level,
    formatters: {
        level(label) {
            return { level: label };
        },
    },
});

export function flush(): void {
    logger.flush();
}

export function createLogger(name: string): Logger {
    return logger.child({ name });
}