import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/footer/siteFooter/SiteFooterLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/PageSetup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/restock-banner/elements/ReStockClientComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/visitor-setup/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/sb-shell/components/visitor-setup/DatadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UtmCookieSetup"] */ "/app/apps/sb-shell/components/visitor-setup/UtmCookieSetup.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/builder/clientUtils.tsx");
