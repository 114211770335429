'use client';

import { load, BotDetectionResult } from '@fingerprintjs/botd';
import { createLogger } from 'logging';

const logger = createLogger('botDetection');

export async function detectBot(): Promise<BotDetectionResult> {
    try {
        const botd = await load();
        const result = await botd.detect();
        return result;
    } catch (error: unknown) {
        logger.error(error, 'Bot detection failed');
        throw error;
    }
}
