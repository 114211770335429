import { Close } from '@videoblocks/react-icons';

const ReStockCloseButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <button className="md:flex-shrink-0 border-none" onClick={onClick}>
            <Close className="w-4 h-4 fill-current" />
        </button>
    );
};

export default ReStockCloseButton;
