'use client';

import { setBuilderUserAttributes } from 'builder/clientUtils';
import { incrementTelemetry, isOrganicReferrer } from 'core-events';
import { JSX, startTransition, useEffect } from 'react';

import { detectBot } from '../lib/botDetection';

export function UtmCookieSetup(): JSX.Element {
    useEffect(() => {
        startTransition(() => {
            detectBot().then((result) => {
                const payload = {
                    url: window.location.href,
                    isBot: result.bot,
                };

                void fetch('/api/visitor-setup', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (result.bot) {
                    void incrementTelemetry('visitor.botVisitorIdDenied');
                }
            });
        });

        // determine if traffic is organic for Builder.io Segmentation
        if (window !== undefined && window.location !== undefined && window.location.href !== undefined) {
            const searchParams = new URL(window.location.href).searchParams ?? new URLSearchParams();
            setBuilderUserAttributes({ organic: isOrganicReferrer(searchParams) });
        }
    }, []);
    return <></>;
}
