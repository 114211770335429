'use client';

import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { useEffect } from 'react';

import fetchUserData from '../../actions/fetchDatadogUserAction';

interface DatadogInitProps {
    config: Partial<RumInitConfiguration>;
    enabled?: string | undefined;
}

export default function DatadogInit({ config, enabled }: DatadogInitProps) {
    useEffect(() => {
        if (enabled === 'true' && !datadogRum.getInternalContext()) {
            datadogRum.init(config as RumInitConfiguration);
            fetchUserData().then((userData) => {
                // Apply user data to Datadog RUM.
                if (userData) {
                    datadogRum.setUser({
                        id: userData.userId,
                        isLoggedIn: userData.isLoggedIn,
                    });
                }
            });
        }
    }, [config, enabled]);

    return <></>;
}
