'use client';

import { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import ReStockCloseButton from './ReStockCloseButton';

export default function ReStockClientComponent({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const banner = localStorage.getItem('hide-restock-banner');
        if (banner) {
            setShowBanner(false);
            return;
        }
        setShowBanner(true);
    }, []);

    const closeBanner = () => {
        localStorage.setItem('hide-restock-banner', 'true');
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div
            className={twMerge(
                'font-polysans flex gap-4 bg-gray-900 text-white w-full px-6 pt-3 pb-2 mt-32 lg:mt-16',
                className,
            )}
        >
            {children}
            <ReStockCloseButton onClick={closeBanner} />
        </div>
    );
}
